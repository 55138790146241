import { setCookie, getCookie, checkDevice, closeModal } from "./common";

var trans;
var slideIndex = 1;
var nextSlide = 0;
var slideTimer;

$("document").ready(function () {
  translatePage();
  startSlideShow();
  initClickListeners();
  checkDevice();
  window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };
});
function initClickListeners() {
  downloadApp.querySelector(".close").addEventListener("click", function () {
    closeModal("downloadApp");
  });
  myTopnav.querySelector(".icon").addEventListener("click", function () {
    openNav();
  });
  btnTranslate.addEventListener("click", function () {
    showDropdown();
  });
  aEn.addEventListener("click", function () {
    translatePage("en");
  });
  aEs.addEventListener("click", function () {
    translatePage("es");
  });
  document.getElementById("closeNav").addEventListener("click", function () {
    closeNav();
  });
  slideShow.querySelector(".prev").addEventListener("click", function () {
    plusSlides(-1);
  });
  slideShow.querySelector(".next").addEventListener("click", function () {
    plusSlides(1);
  });
  dot1.addEventListener("click", function () {
    currentSlide(1);
  });
  dot2.addEventListener("click", function () {
    currentSlide(2);
  });
  dot3.addEventListener("click", function () {
    currentSlide(3);
  });
  dot4.addEventListener("click", function () {
    currentSlide(4);
  });
}

function openNav() {
  document.getElementById("closeNav").style.display = "block";
  var sidePanel = document.getElementById("resTopnav");
  sidePanel.style.display = "block";
}

function closeNav() {
  var sidePanel = document.getElementById("resTopnav");
  sidePanel.style.display = "none";
  // sidePanel.style.width = "0";
  // sidePanel.style.overflow = "hidden";
}

function startSlideShow() {
  showSlides(slideIndex);
}

function resetTimer() {
  clearTimeout(slideTimer);
}

function plusSlides(n) {
  showSlides((slideIndex += n));
}

function currentSlide(n) {
  showSlides((slideIndex = n));
}

function showSlides(n) {
  var i;
  var slides = document.getElementsByClassName("mySlides");
  var dots = document.getElementsByClassName("dot");

  if (!n) {
    nextSlide++;
    n = nextSlide;
    slideIndex = n;
    // console.log("n was changed: " + n);
  } else {
    nextSlide = n;
    if (slideTimer) {
      resetTimer();
    }
  }

  if (n > slides.length) {
    slideIndex = 1;
    nextSlide = slideIndex;
  }

  if (n < 1) {
    slideIndex = slides.length;
    nextSlide = slideIndex;
  }

  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  slides[slideIndex - 1].style.display = "flex";
  dots[slideIndex - 1].className += " active";

  slideTimer = setTimeout(showSlides, 5000);
}

var language = {
  eng: {
    home: "Home",
    welcome: "Welcome",
    Chat: "Chat",
    Feat: "Features",
    Download: "Download",
    About: "About",
    s1t: "All in one chat",
    s1c:
      "Get in touch with your favorite radio stations.<br>" +
      "In ChatMurcianys, you can chat, listen to music, and request songs from your" +
      "favorite radio stations.",
    s2t: "Chat with anyone around the world",
    s2c: "Chat with friends and family from anywhere in the world.",
    s3t: "Group chats",
    s3c: "Create group chats and share your favorite moments in your story",
    s4t: "Own a radio station?",
    s4c: "Add your radio station to our app!<br>Chat on the Android app or here on our website.",
    currentRadios: "Most Popular Radios",
    regRadio: "Register Radio Station",
    download_app: "Download App",
    idiom: "En",
    sponsorLink:
      "https://www.paypal.com/donate/?hosted_button_id=SLQ8JNNUX2GQQ",
    donateSponsor: "Donate and Sponsor",
    donateLink: "https://www.paypal.com/donate/?hosted_button_id=H9J6PFB7YYHES",
    donate: "Donate",
    donateInfo:
      "Donate to support our app development. Make a recurring monthly donation of $50 or more to become a sponsor and we will feature you here on our website.",
    ourSupporters: "Our sponsors",
    buyNow: "Buy now",
    regPod: "Register podcast",
    forRadPod: "For radios/podcasts",
    forEveryone: "For everyone",
    pRegRadPod: "Register your radio station or podcast for free",
    dInviteMsg: "Invite your audience to listen and connect with you",
    liSendMsg: "Send real-time messages",
    liSong:
      "Accept song requests or know exactly how you audience feels about your episodes",
    liShare: "Share photos, videos, and audios in the chat and on your story",
    pEveryone:
      "Chat with radio stations, podcasts, friends, and family from around the world",
    dAccess: "With ChatMurcianys you have free* access to the following:",
    liSendPvaMsg: "Send real-time photo, video, and audio messages.",
    liDiscoverRadPod: "Discover radio stations and podcasts",
    liHelpRadPod: "Help radio hosts/podcasters by sending them gifts in chat",
    liRegRadPod: "Register your own radio station and/or podcast",
    liMonetize: "Monetize your radio by accepting gifts",
    liSharePVA:
      "Share photos, videos, and audios to your story for friends to see",
    listenEvery: "Listen everywhere",
    pListenEvery: `Enjoy listening to every radio station and podcast available in
      ChatMurcianys on your Andorid phone, tablet, or any browser of your
      choice.`,
    headphones: "Headphones",
    chatIcon: "Chat icon",
    chatAnywhere: "Chat Anywhere Everywhere",
    msgAnyone: `Message anyone around the world from your phone, tablet, or from
    any computer.`,
    phoneIcon: "Phone icon",
    freeVoice: "Free* voice calls",
    callAnyone: `Call anyone around the world from your phone or tablet for
    free*`,
    comingSoon: "Coming soon: Voice calls from ChatMurcianys on your computer",
    dataCharges: "*Data charges may apply. Contact your provider for details.",
    freeVideo: "Free* Video Calls",
    videoAnyone:
      "Make video calls to anyone around the world from your phone, tablet for free*",
    soonVideo: "Coming soon: Video calls from ChatMurcianys on your computer",
    radioIcon: "Radio icon",
    findStation: "Find Your Station",
    newStations: `Discover new stations and meet new people. The radio stations in
    ChatMurcianys accept song requests and have an open chat
    available where you can chat with people who share your same
    taste in music.`,
    newPods: `Discover new podcasts and meet new people. Share your thoughts and
    meet new people in the chat room available for all podcasts.`,
    findPod: "Find your podcast",
    podIcon: "Podcast icon",
    moreFt: "More features",
  },
  es: {
    home: "Inicio",
    welcome: "Bienvenid@s",
    Chat: "Chat",
    Feat: "Funciones",
    Download: "Descargar",
    About: "Sobre",
    s1t: "Todo en un chat",
    s1c: "Pónte en contacto con tus estaciones de radio favoritas.<br>En ChatMurcianys, puedes chatear, escuchar música y solicitar canciones de tus estaciones de radio favoritas.",
    s2t: "Envia mensajes a cualquier parte del mundo",
    s2c: "Mensajea con amigos y familia en cualquier parte del mundo",
    s3t: "Mensajes en Grupos",
    s3c: "Crea grupos y comparte tus momentos favoritos en tu estado",
    s4t: "¿Eres dueño de una radio?",
    s4c: "Agrega tu estación de radio a nuestra aplicación.<br>Chatea por la aplicación en Android o aquí en el sitio web.",
    currentRadios: "Las Radios Más Populares",
    regRadio: "Registrar Radio",
    download_app: "Descargar Aplicación",
    idiom: "Es",
    sponsorLink:
      "https://www.paypal.com/donate/?hosted_button_id=8BJL6DCPVDMPC",
    donateSponsor: "Donar y patrocinar",
    donateLink: "https://www.paypal.com/donate/?hosted_button_id=86Y9TMJQF4C2W",
    donate: "Donar",
    donateInfo:
      "Dona para apoyar el desarrollo de nuestra aplicación. Haz una donación mensual recurrente de $50 o más para convertirte en patrocinador y te presentaremos aquí en nuestro sitio web.",
    ourSupporters: "Nuestros patrocinadores",
    buyNow: "Comprar ahora",
    regPod: "Registrar podcast",
    forRadPod: "Para radios/podcasts",
    forEveryone: "Para todos",
    pRegRadPod: "Registra tu estación de radio o podcast gratis",
    dInviteMsg:
      "Invita a tu audiencia a escuchar y conectarse contigo más fácilmente sin importar dónde se encuentren",
    liSendMsg: "Enviar mensajes en tiempo real",
    liSong:
      "Acepta solicitudes de canciones o conoce exactamente cómo se siente tu audiencia con respecto a tus episodios",
    liShare: "Comparte fotos, videos y audios en el chat y en tu estado",

    pEveryone:
      "Chatea con estaciones de radio, podcasts, amigos y familiares de todo el mundo",
    dAccess: "Con ChatMurcianys tienes acceso gratuito* a lo siguiente:",
    liSendPvaMsg: "Envía fotos, videos y mensajes de audio en tiempo real",
    liDiscoverRadPod: "Descubre emisoras de radio y podcasts",
    liHelpRadPod:
      "Ayuda a los locutores de radio/podcasters enviándoles regalos en el chat",
    liRegRadPod: "Registra tu propia emisora de radio y/o podcast",
    liMonetize: "Monetiza tu radio aceptando regalos",
    liSharePVA:
      "Comparte fotos, videos y audios en tu historia para que tus amistades los vean",
    listenEvery: "Escucha en todas partes",
    pListenEvery: `Disfruta escuchando todas las 
    estaciones de radio y podcast disponibles en ChatMurcianys en tu teléfono Android, tableta o cualquier navegador de tu elección.`,
    headphones: "Auriculares",
    chatIcon: "Icono de chat",
    chatAnywhere: "Chat en cualquier lugar en todas partes",
    msgAnyone: `Envía mensajes a cualquier persona en todo el mundo desde tu teléfono, tableta o desde cualquier computadora.`,
    phoneIcon: "Icono de teléfono",
    freeVoice: "Llamadas de voz gratis*",
    callAnyone:
      "Llama a cualquier persona de todo el mundo desde tu teléfono o tableta gratis*",
    comingSoon: `Próximamente: Llamadas de voz de ChatMurcianys desde tu computadora`,
    dataCharges:
      "*Pueden aplicarse cargos por datos. Comuníquese con su proveedor para obtener más detalles.",
    freeVideo: "Videollamadas gratis*",
    videoAnyone:
      "Realiza videollamadas a cualquier persona en todo el mundo desde tu teléfono o tableta de forma gratuita*",
    soonVideo: "Próximamente: Videollamadas de ChatMurcianys en tu computadora",
    radioIcon: "Icono de radio",
    findStation: "Encuentra tu estación",
    newStations: `Descubre nuevas estaciones y conoce gente nueva. Las emisoras de 
    radio en ChatMurcianys aceptan peticiones de canciones y tienen disponible un chat abierto donde puedes charlar con 
    personas que comparten tu mismo gusto musical.`,
    newPods: `Descubre nuevos podcasts y conoce gente nueva. 
    Comparte tus pensamientos y conoce gente nueva en la sala de chat disponible para todos los podcasts.`,
    findPod: "Encuentra tu podcast",
    podIcon: "Icono de podcast",
    moreFt: "Más funciones",
  },
};
function showDropdown() {
  document.getElementById("langDropdown").classList.toggle("show");
}
function translatePage(lang) {
  var translate = false;
  if (lang) {
    if (lang.includes("es")) {
      if (trans && trans.idiom == "Es") {
        return;
      }
      trans = language.es;
      document.getElementsByTagName("html")[0].setAttribute("lang", "es");
      var langs = langDropdown.querySelectorAll("a");
      langs[0].className = langs[0].className.replace(" set", "");
      langs[1].className += " set";
      setCookie("lang", "es", 365);
    } else {
      if (trans && trans.idiom == "En") {
        return;
      }
      trans = language.eng;
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      var langs = langDropdown.querySelectorAll("a");
      langs[0].className += " set";
      langs[1].className = langs[1].className.replace(" set", "");
      setCookie("lang", "en", 365);
    }
    translate = true;
  } else if (getCookie("lang") != "") {
    if (getCookie("lang").includes("es")) {
      trans = language.es;
      document.getElementsByTagName("html")[0].setAttribute("lang", "es");
      var langs = langDropdown.querySelectorAll("a");
      langs[0].className = langs[0].className.replace(" set", "");
      langs[1].className += " set";
      translate = true;
      setCookie("lang", "es", 365);
    } else {
      trans = language.eng;
    }
  } else if (navigator.language && navigator.language.includes("es")) {
    trans = language.es;
    document.getElementsByTagName("html")[0].setAttribute("lang", "es");
    var langs = langDropdown.querySelectorAll("a");
    langs[0].className = langs[0].className.replace(" set", "");
    langs[1].className += " set";
    translate = true;
  } else {
    trans = language.eng;
  }

  if (translate) {
    menuLang.textContent = trans.idiom;
    var slideHeaders = slideShow.querySelectorAll("h2");
    var slidePs = slideShow.querySelectorAll("p");
    slideHeaders[0].innerHTML = trans.s1t;
    slideHeaders[1].innerHTML = trans.s2t;
    slideHeaders[2].innerHTML = trans.s3t;
    slideHeaders[3].innerHTML = trans.s4t;
    slidePs[0].innerHTML = trans.s1c;
    slidePs[1].innerHTML = trans.s2c;
    slidePs[2].innerHTML = trans.s3c;
    slidePs[3].innerHTML = trans.s4c;
    navChat.textContent = trans.Chat;
    navFeat.textContent = trans.Feat;
    navDownload.textContent = trans.Download;
    navAbout.textContent = trans.About;
    radios.querySelector("h3").textContent = trans.currentRadios;
    resChat.textContent = trans.Chat;
    resFeat.textContent = trans.Feat;
    resDownload.textContent = trans.Download;
    resAbout.textContent = trans.About;
    document.getElementById("btnAddRadioSlide").textContent = trans.regRadio;
    btnDownload.textContent = trans.download_app;
    var aSponsor = document.getElementById("aSponsor");
    aSponsor.href = trans.sponsorLink;
    aSponsor.textContent = trans.donateSponsor;
    var aDonate = document.getElementById("aDonate");
    aDonate.href = trans.donateLink;
    aDonate.textContent = trans.donate;
    var divDonations = document.getElementById("divDonations");
    divDonations.querySelector("p").textContent = trans.donateInfo;
    divDonations.querySelector("h3").textContent = trans.ourSupporters;
    $(".t-regRadio").text(trans.regRadio);
    $(".t-regPod").text(trans.regPod);
    $("#navHome").text(trans.home);
    $(".txt-buy").text(trans.buyNow);
    $(".segments.radios h3").text(trans.forRadPod);
    $(".segments.everyone h3").text(trans.forEveryone);
    $(".segments.radios p").text(trans.pRegRadPod);
    $(".segments.radios .dSum").text(trans.dInviteMsg);
    var radSegli = document.querySelectorAll(".segments.radios ul li");
    radSegli[0].textContent = trans.liSendMsg;
    radSegli[1].textContent = trans.liSong;
    radSegli[2].textContent = trans.liShare;
    $(".segments.everyone p").text(trans.pEveryone);
    $(".segments.everyone .dSum").text(trans.dAccess);
    var everySegli = document.querySelectorAll(".segments.everyone ul li");
    everySegli[0].textContent = trans.liSendPvaMsg;
    everySegli[1].textContent = trans.liDiscoverRadPod;
    everySegli[2].textContent = trans.liHelpRadPod;
    everySegli[3].textContent = trans.liRegRadPod;
    everySegli[4].textContent = trans.liMonetize;
    everySegli[5].textContent = trans.liSharePVA;
    $(".alt-headphones").text(trans.headphones);
    $(".alt-chat-icon").text(trans.chatIcon);
    $(".alt-phone-icon").text(trans.phoneIcon);
    $(".alt-phone-icon").text(trans.radioIcon);
    $(".alt-pod-icon").text(trans.podIcon);
    $(".txt-data-charges").text(trans.dataCharges);

    var hFeatures = document.querySelectorAll(".features h2");
    hFeatures[0].textContent = trans.listenEvery;
    hFeatures[1].textContent = trans.chatAnywhere;
    hFeatures[2].textContent = trans.freeVoice;
    hFeatures[3].textContent = trans.freeVideo;
    hFeatures[4].textContent = trans.findStation;
    hFeatures[5].textContent = trans.findPod;

    var pFeatures = document.querySelectorAll(".features p");
    pFeatures[0].textContent = trans.pListenEvery;
    pFeatures[1].textContent = trans.msgAnyone;
    pFeatures[2].textContent = trans.callAnyone;
    pFeatures[3].textContent = trans.videoAnyone;
    pFeatures[4].textContent = trans.newStations;
    pFeatures[5].textContent = trans.newPods;

    var spFeatures = document.querySelectorAll(".features span");
    spFeatures[0].textContent = trans.comingSoon;
    spFeatures[2].textContent = trans.soonVideo;
    $(".txt-more-ft").text(trans.moreFt);
  }
}
