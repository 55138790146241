import phClose from "url:../images/gph5.gif";
import phGif from "url:../images/gph1.gif";
var divModal;
var modalMessage;
var days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
var dias = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];
var dayAbbr = ["u", "m", "t", "w", "r", "f", "s"];
var genres = [
  "Arts and culture",
  "Business and economics",
  "Comedy",
  "Education",
  "Fantasy and science fiction",
  "Food and cooking",
  "Health and wellness",
  "History",
  "Kids and family",
  "Language learning",
  "Media and entertainment",
  "Music",
  "News and Politics",
  "Paranormal",
  "Philosophy",
  "Radio drama",
  "Romance",
  "Adult content",
  "Religion and spirituality",
  "Science",
  "Sports and recreation",
  "Storytelling",
  "Talk show replays",
  "Technology",
  "True crime",
];

var generos = [
  "Arte y cultura",
  "Negocios y Economía",
  "Comedia",
  "Educación",
  "Fantasía y ciencia ficción",
  "Comida y cocina",
  "Salud y bienestar",
  "Historial",
  "Niños y familia",
  "Aprendizaje de idiomas",
  "Medios y entretenimiento",
  "Música",
  "Noticias y política",
  "Paranormal",
  "Filosofía",
  "Radio drama",
  "Romance",
  "Contenido para adultos",
  "Religión y espiritualidad",
  "Ciencia",
  "Deportes y recreación",
  "Narración de cuentos",
  "Repeticiones de programas de entrevistas",
  "Tecnología",
  "Crimen verdadero",
];
var langs = [
  "Afrikaans",
  "Albanian",
  "Arabic",
  "Belarusian",
  "Bulgarian",
  "Bengali",
  "Catalan",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Haitian",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Kannada",
  "Korean",
  "Lithiuanian",
  "Latvian",
  "Macedonian",
  "Marathi",
  "Malay",
  "Maltese",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swedish",
  "Swahili",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Vietnamese",
  "Welsh",
];

var lengs = [
  "Africaans",
  "Albanés",
  "Arábica",
  "Bielorruso",
  "Búlgaro",
  "Bengalí",
  "Catalán",
  "Chino",
  "Croata",
  "Checo",
  "Danés",
  "Holandés",
  "Inglés",
  "Esperanto",
  "Estonio",
  "Finlandés",
  "Francés",
  "Gallego",
  "Georgiano",
  "Alemán",
  "Griego",
  "Gujarati",
  "Haitiano",
  "Hebreo",
  "Hindi",
  "Húngaro",
  "Islandés",
  "Indonesio",
  "Irlandés",
  "Italiano",
  "Japonés",
  "Kannada",
  "Coreano",
  "Lituano",
  "Letón",
  "Macedonio",
  "Marathi",
  "Malayo",
  "Maltés",
  "Noruego",
  "Persia",
  "Polaco",
  "Portugués",
  "Romano",
  "Ruso",
  "Eslovaco",
  "Esloveno",
  "Español",
  "Sueco",
  "Swahili",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Tailandés",
  "Turco",
  "Ucraniano",
  "Urdu",
  "Vietnamita",
  "Galés",
];
var langCode = [
  "af",
  "sq",
  "ar",
  "be",
  "bg",
  "bn",
  "ca",
  "zh",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "eo",
  "et",
  "fi",
  "fr",
  "gl",
  "ka",
  "de",
  "el",
  "gu",
  "ht",
  "he",
  "hi",
  "hu",
  "is",
  "id",
  "ga",
  "it",
  "ja",
  "kn",
  "ko",
  "lt",
  "lv",
  "mk",
  "mr",
  "ms",
  "mt",
  "no",
  "fa",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "es",
  "sv",
  "sw",
  "tl",
  "ta",
  "te",
  "th",
  "tr",
  "uk",
  "ur",
  "vi",
  "cy",
];
const paisList = [
  "Afganistán",
  "Albania",
  "Argelia",
  "Samoa Americana",
  "Andorra",
  "Angola",
  "Anguila",
  "Antártida",
  "Antigua y Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaiyán",
  "Bahamas",
  "Bahréin",
  "Bangladesh",
  "Barbados",
  "Bielorrusia",
  "Bélgica",
  "Belice",
  "Benin",
  "Islas Bermudas",
  "Bután",
  "Bolivia (Estado Plurinacional de)",
  "Bonaire, Sint Eustatius y Saba",
  "Bosnia y Herzegovina",
  "Botswana",
  "Isla Bouvet",
  "Brasil",
  "Territorio Británico del Océano Índico",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Camboya",
  "Camerún",
  "Canadá",
  "Islas Caimán",
  "República Centroafricana",
  "Chad",
  "Chile",
  "Porcelana",
  "Isla de Navidad",
  "Islas Cocos (Keeling)",
  "Colombia",
  "Comoras",
  "Congo (República Democrática del)",
  "Congo",
  "Islas Cook",
  "Costa Rica",
  "Croacia",
  "Cuba",
  "Curazao",
  "Chipre",
  "Chequia",
  "Côte d'Ivoire",
  "Dinamarca",
  "Djibouti",
  "Dominica",
  "República Dominicana",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Guinea Ecuatorial",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Etiopía",
  "Islas Falkland [Malvinas]",
  "Islas Faroe",
  "Fiji",
  "Finlandia",
  "Francia",
  "Guayana Francesa",
  "Polinesia francés",
  "Territorios Franceses del Sur",
  "Gabón",
  "Gambia",
  "Georgia",
  "Alemania",
  "Ghana",
  "Gibraltar",
  "Grecia",
  "Groenlandia",
  "Granada",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guayana",
  "Haití",
  "Islas Heard y McDonald",
  "Santa Sede",
  "Honduras",
  "Hong Kong",
  "Hungría",
  "Islandia",
  "India",
  "Indonesia",
  "Irán (República Islámica de)",
  "Irak",
  "Irlanda",
  "Isla del hombre",
  "Israel",
  "Italia",
  "Jamaica",
  "Japón",
  "Suéter",
  "Jordán",
  "Kazajstán",
  "Kenia",
  "Kiribati",
  "Corea (República Popular Democrática de)",
  "Corea (República de)",
  "Kuwait",
  "Kirguistán",
  "República Democrática Popular Lao",
  "Letonia",
  "Líbano",
  "Lesoto",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malasia",
  "Maldivas",
  "Malí",
  "Malta",
  "Islas Marshall",
  "Martinica",
  "Mauritania",
  "Mauricio",
  "Mayotte",
  "México",
  "Micronesia (Estados Federados de)",
  "Moldova (la República de)",
  "Mónaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marruecos",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Países Bajos",
  "Nueva Caledonia",
  "Nueva Zelanda",
  "Nicaragua",
  "Níger",
  "Nigeria",
  "Niue",
  "Isla Norfolk",
  "Islas Marianas del Norte",
  "Noruega",
  "Omán",
  "Pakistán",
  "Palau",
  "Palestina, Estado de",
  "Panamá",
  "Papúa Nueva Guinea",
  "Paraguay",
  "Perú",
  "Filipinas",
  "Pitcairn",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "Katar",
  "República de Macedonia del Norte",
  "Rumania",
  "Federación Rusa",
  "Ruanda",
  "Cita",
  "San Bartolomé",
  "Santa Elena, Ascensión y Tristan da Cunha",
  "Saint Kitts y Nevis",
  "Santa Lucía",
  "San Martín (parte francesa)",
  "San Pedro y Miquelón",
  "San Vicente y las Granadinas",
  "Samoa",
  "San Marino",
  "Santo Tomé y Príncipe",
  "Arabia Saudita",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leona",
  "Singapur",
  "Sint Maarten (parte holandesa)",
  "Eslovaquia",
  "Eslovenia",
  "Islas Salomón",
  "Somalia",
  "Sudáfrica",
  "Georgia del sur y las islas Sandwich del sur",
  "Sudán del Sur",
  "España",
  "Sri Lanka",
  "Sudán",
  "Surinam",
  "Svalbard y Jan Mayen",
  "Suecia",
  "Suiza",
  "República Árabe Siria",
  "Taiwán",
  "Tayikistán",
  "República Unida de Tanzanía",
  "Tailandia",
  "Timor-Leste",
  "Ir",
  "Tokelau",
  "Tonga",
  "Trinidad y Tobago",
  "Túnez",
  "Pavo",
  "Turkmenistán",
  "Islas Turcas y Caicos",
  "Tuvalu",
  "Uganda",
  "Ucrania",
  "Emiratos Árabes Unidos",
  "Reino Unido de Gran Bretaña e Irlanda del Norte",
  "Islas menores alejadas de los Estados Unidos",
  "Estados Unidos",
  "Uruguay",
  "Uzbekistán",
  "Vanuatu",
  "Venezuela (República Bolivariana de)",
  "Vietnam",
  "Islas Vírgenes (Británicas)",
  "Islas Vírgenes (EE. UU.)",
  "Wallis y Futuna",
  "Sahara Occidental",
  "Yemen",
  "Zambia",
  "Zimbabue",
  "Islas Aland",
];
const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

const countryCodeList = [
  "AF",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CD",
  "CG",
  "CK",
  "CR",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "CI",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KP",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "MK",
  "RO",
  "RU",
  "RW",
  "RE",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "UM",
  "US",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "VG",
  "VI",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW",
  "AX",
];
var podClass = {
  email: null,
  pod_email: null,
  title: null,
  lcTitle: null,
  host: null,
  uid: null,
  pid: null,
  desc: null,
  feed: null,
  web: null,
  phone: null,
  lang: null,
  country: null,
  genre: null,
  fb: null,
  tw: null,
  ig: null,
  tk: null,
  yt: null,
  wa: null,
  msgWelcome: null,
  icon: null,
  likes: null,
  date: null,
  revenue: null,
  screenBlock: null,
  verified: null,
  donation: null,
  url: null,
};
export function initCommonViews() {
  divModal = document.getElementById("myModal");
  modalMessage = document.getElementById("modalMessage");
  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == this.divModal) {
      this.closeMyModal(divModal);
    }
  };
}

export function checkDevice() {
  var isAndroid = /(android)/i.test(navigator.userAgent);
  if (isAndroid) {
    setDisplay(downloadApp, "flex");
  }
}

export function closeModal(modalId) {
  if (!modalId.style) {
    modalId = document.getElementById(modalId);
  }

  modalId.style.display = "none";
}
export function closeMyModal() {
  divModal.style.display = "none";
}

export function openCommonModal() {
  //closeNav();
  divModal.style.display = "flex";
}

export function setDisplay(element, displayType) {
  if (!element.style) {
    element = document.getElementById(element);
  }
  element.style.display = displayType;
}

export function setModalMessage(message) {
  if (message != undefined) {
    modalMessage.innerHTML = message;
  }
}

export function showToast(message, elmnt, duration) {
  var x = document.getElementById(elmnt ? elmnt : "snackbar");
  if (x == null) {
    x = document.getElementById("toast");
    if (x == null) {
      return;
    }
  }
  x.textContent = message;
  x.className = "show";
  setTimeout(
    function () {
      x.className = x.className.replace("show", "");
    },
    duration ? duration : 3000
  );
}

export function getAudioTime(millis) {
  let sec = Math.floor(millis / 1000);
  let hrs = Math.floor(sec / 3600);
  sec -= hrs * 3600;
  let min = Math.floor(sec / 60);
  sec -= min * 60;

  sec = "" + sec;
  sec = ("00" + sec).substring(sec.length);

  if (hrs > 0) {
    min = "" + min;
    min = ("00" + min).substring(min.length);
    return hrs + ":" + min + ":" + sec;
  } else {
    return min + ":" + sec;
  }
}

export function getDuration(seconds) {
  sec = Math.floor(seconds);
  min = Math.floor(sec / 60);
  min = min >= 10 ? min : "0" + min; //if min >10 don't add 0 infront
  sec = Math.floor(sec % 60);
  sec = sec >= 10 ? sec : "0" + sec;
  return min + ":" + sec;
}

export function sameDay(d1, d2) {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
}

export function formatMessageDate(date, lang) {
  var messageDate;
  const today = new Date();
  const oneDay = 60 * 60 * 24 * 1000;
  const yesterday = today - oneDay;

  var month;
  if (lang != null && lang === "es") {
    month = meses[date.getMonth()];
  } else {
    month = months[date.getMonth()];
  }

  var day = date.getDate();
  var year = date.getFullYear();

  if (sameDay(today, new Date(date))) {
    messageDate = "Today";
  } else if (date >= yesterday && date < today) {
    messageDate = "Yesterday";
  } else {
    messageDate = month + " " + day + ", " + year;
  }
  return messageDate;
}

export function formatTime(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? " PM" : " AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + ampm;
}

export function formatMilTime(date, end) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? " PM" : " AM";
  if (end && ampm == " AM" && hours == 0) {
    hours = "24";
  } else if (hours == 0) {
    hours = "00";
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  // if (selStartAmPm.value == "am") {
  //     mH = hr == 12? "00":hrs;
  // }

  return hours + ":" + minutes + " " + ampm;
}

export function getDayAbbr(index) {
  if (index < 0 || index >= dayAbbr.length) {
    index = 0;
  }
  return dayAbbr[index];
}

export function sendVoiceMsg(
  url,
  bmRef,
  chatRef,
  rMessage,
  rKey,
  chatType,
  userInfo,
  chatInfo,
  chatKey,
  position,
  groupList,
  audDuration
) {
  var time = new Date().getTime();
  var messageKey = chatRef.push().key;
  var message = {
    userId: userInfo.userId,
    userName: userInfo.name,
    type: "Audio",
    isUploaded: true,
    audioFile: null,
    phoneFilePath: "web",
    filePath: "web",
    receiverName: chatInfo.name,
    message_key: null,
    receiverId: chatKey,
    audioLength: audDuration,
    isReply: false,
    rePosition: null,
    reSenderName: null,
    reSenderId: null,
    reType: null,
    reMessageKey: null,
    reText: null,
    reFileUrl: null,
    reMediaLength: null,
    messageTime: time,
    web: true,
  };
  //
  if (rKey) {
    message.isReply = true;
    message.rePosition = position;
    message.reSenderId = rMessage.userId;
    message.reSenderName = rMessage.userName;
    message.reType = rMessage.type;
    message.reMessageKey = rKey;
    switch (rMessage.type) {
      case "Text":
      case "SongRequest":
        message.reText = rMessage.textMessage;
        break;
      case "Audio":
        message.reMediaLength = getAudioTime(rMessage.audioLength);
        break;
      case "Picture":
      case "Gif":
        message.reFileUrl = rMessage.filePath;
        break;
      case "TextPicture":
      case "TextGif":
        message.reText = rMessage.textMessage;
        message.reFileUrl = rMessage.filePath;
        break;
      case "Video":
        message.reFileUrl = rMessage.videoThumb;
        message.reMediaLength = rMessage.videoLength;
        break;
      case "TextVideo":
        message.reText = rMessage.textMessage;
        message.reFileUrl = rMessage.videoThumb;
        message.reMediaLength = rMessage.videoLength;
        break;
    }
  }

  var bookmark = {
    type: chatType,
    name: chatInfo.name ? chatInfo.name : null,
    id: chatKey,
    imageUrl: null,
    messageType: "Audio",
    unread_messages: 0,
    email: null,
    date: time,
    radioStream: null, //for radios || podcast
    creatorId: null, //for groups
    lowCaseName: null,
    audioDuration: audDuration,
  };

  var audioStRef,
    friendBmRef,
    friendChatRef,
    friendBm,
    groupBmRef,
    groupChatRef;
  switch (chatType) {
    case "RadioChat":
      audioStRef = firebase
        .storage()
        .ref()
        .child(`Radio Stations/${chatKey}/Audios/${messageKey}`);
      if (chatInfo.radio_Stream) {
        message.receiverName = chatInfo.radio_Name;
        bookmark.radioStream = chatInfo.radio_Stream;
        bookmark.name = chatInfo.radio_Name;
        bookmark.lowCaseName = chatInfo.radio_Name.toLowerCase();
        if (chatInfo.radio_image) {
          bookmark.imageUrl = chatInfo.radio_image;
        }
      } else {
        bookmark.radioStream = chatInfo.radioStream;
        if (chatInfo.imageUrl) {
          bookmark.imageUrl = chatInfo.imageUrl;
        }
      }

      // chatRef.child(messageKey).set(message, function (error) {
      //   if (error) {
      //     //message failed
      //     showToast("Error sending message. Try again later.");
      //   } else {
      //     //message sent
      //     bmRef.set(bookmark);
      //     //showToast("Petition sent successfully");
      //   }
      // });
      break;
    case "privateChat":
      audioStRef = firebase
        .storage()
        .ref()
        .child(`Users/${userInfo.userId}/${chatKey}/Audios/${messageKey}`);
      bookmark.lowCaseName = chatInfo.name.toLowerCase();
      friendChatRef = firebase
        .database()
        .ref(
          "PrivateChat/" + chatKey + "/" + userInfo.userId + "/" + messageKey
        );

      friendBm = {
        type: chatType,
        name: userInfo.name,
        id: userInfo.userId,
        imageUrl: userInfo.profileImage,
        messageType: "Audio",
        unread_messages: 0,
        email: userInfo.email,
        date: time,
        radioStream: null, //for radios || podcast
        creatorId: null, //for groups
        lowCaseName: userInfo.name.toLowerCase(),
        audioDuration: audDuration,
      };
      if (chatInfo.profile_Image) {
        bookmark.imageUrl = chatInfo.profile_Image;
      } else if (chatInfo.imageUrl) {
        bookmark.imageUrl = chatInfo.imageUrl;
      }

      if (chatInfo.email) {
        bookmark.email = chatInfo.email;
      }
      friendBmRef = firebase
        .database()
        .ref("Users/" + chatKey + "/Chat_Bookmarks/" + userInfo.userId);

      // chatRef.child(messageKey).set(message, function (error) {
      //   if (error) {
      //     //request failed
      //     showToast("Error sending message. Try again later.");
      //   } else {
      //     //request sent
      //     bmRef.set(bookmark);

      //     friendChatRef.set(message, function (error) {
      //       if (error) {
      //         //message failed
      //         showToast("Error sending friend message. Try again later.");
      //       } else {
      //         //message sent
      //         chatRef.child(messageKey).child("sent").set(true);
      //         friendBmRef.set(friendBm);
      //       }
      //     });
      //   }
      // });

      break;
    case "GroupChat":
      audioStRef = firebase
        .storage()
        .ref()
        .child(`GroupChats/${chatKey}/Audios/${messageKey}`);
      bookmark.lowCaseName = chatInfo.name.toLowerCase();
      bookmark.email = chatInfo.email;
      if (chatInfo.imageUrl) {
        bookmark.imageUrl = chatInfo.imageUrl;
      }
      bookmark.creatorId = chatInfo.creatorId;
      groupChatRef = firebase.database().ref("GroupChats/" + chatKey + "/chat");
      groupBmRef = firebase.database().ref("Users");
      // chatRef.child(messageKey).set(message, function (error) {
      //   if (error) {
      //     //message failed
      //     showToast("Error sending petition. Try again later.");
      //   } else {
      //     //message sent
      //     bmRef.set(bookmark);

      //     for (let participant of groupList) {
      //       groupChatRef
      //         .child(participant)
      //         .child(messageKey)
      //         .set(message, function (error) {
      //           if (error) {
      //             //request failed
      //             showToast("Error sending message to group. Try again later.");
      //           } else {
      //             //request sent
      //             groupBmRef
      //               .child(participant)
      //               .child("Chat_Bookmarks")
      //               .child(chatKey)
      //               .set(bookmark);
      //           }
      //         });
      //     }
      //   }
      // });
      break;
    case "podChat":
      audioStRef = firebase
        .storage()
        .ref()
        .child(`podcast/${chatKey}/Audios/${messageKey}`);
      bookmark.name = chatInfo.title ? chatInfo.title : chatInfo.name;
      bookmark.lowCaseName = bookmark.name.toLowerCase();
      bookmark.radioStream = chatInfo.feed;
      bookmark.imageUrl = chatInfo.icon;
      // chatRef.child(messageKey).set(message, function (error) {
      //   if (error) {
      //     showToast("Error sending message. Try again later.");
      //   } else {
      //     //message sent
      //     bmRef.set(bookmark);
      //   }
      // });
      break;
  }

  //upload audio
  var uploadTask = audioStRef.put(url);
  uploadTask.on(
    "state_changed",
    function (snapshot) {},
    function (error) {
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          //showToast(trans.errorStoragePermission);
          console.log("storage/unauthorized");
          break;
        case "storage/canceled":
          //showToast(trans.errorUploadCanceled);
          console.log("storage/canceled");
          break;
        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          //showToast(trans.errorRegister);
          console.log("storage/unknown");
          break;
      }
      //setDisplay(loader, "none");
      //disableButton(btnRegister, false);
    },
    function () {
      // Handle successful uploads on complete
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        message.audioFile = downloadURL;
        chatRef.child(messageKey).set(message, function (error) {
          if (error) {
            //message failed
            console.log(`Error sending message: ${error} Try again later.`);
          } else {
            //message sent
            bmRef.set(bookmark, function (error) {
              if (error) {
                console.log(`bmRef could not be set: ${error}`);
              } else {
                if (friendChatRef != null) {
                  friendChatRef.set(message, function (error) {
                    if (error) {
                      //message failed
                      console.log(
                        "Error sending friend message. Try again later."
                      );
                    } else {
                      //message sent
                      chatRef
                        .child(messageKey)
                        .child("sent")
                        .set(true, function (error) {
                          if (error) {
                            console.log(`Could not set set to true: ${error}`);
                          } else {
                            friendBmRef.set(friendBm);
                          }
                        });
                    }
                  });
                } else if (groupChatRef != null) {
                  for (let participant of groupList) {
                    groupChatRef
                      .child(participant)
                      .child(messageKey)
                      .set(message, function (error) {
                        if (error) {
                          //request failed
                          console.log(
                            `Error sending message to group. Try again later.`
                          );
                        } else {
                          //request sent
                          groupBmRef
                            .child(participant)
                            .child("Chat_Bookmarks")
                            .child(chatKey)
                            .set(bookmark);
                        }
                      });
                  }
                }
              }
            });
            //showToast("Petition sent successfully");
          }
        });
      });
    }
  );
}

export function sendTextMessage(
  input,
  bmRef,
  chatRef,
  rMessage,
  rKey,
  chatType,
  userInfo,
  chatInfo,
  chatKey,
  position,
  groupList
) {
  var time = new Date().getTime();
  var message;
  var messageKey = chatRef.push().key;
  var bookmark;

  message = {
    textMessage: input,
    userName: userInfo.name,
    userId: userInfo.userId,
    type: "Text",
    isReply: false,
    rePosition: null,
    reSenderName: null,
    reSenderId: null,
    reType: null,
    reMessageKey: null,
    reText: null,
    reFileUrl: null,
    reMediaLength: null,
    messageTime: time,
  };
  if (rKey) {
    message.isReply = true;
    message.rePosition = position;
    message.reSenderId = rMessage.userId;
    message.reSenderName = rMessage.userName;
    message.reType = rMessage.type;
    message.reMessageKey = rKey;
    switch (rMessage.type) {
      case "Text":
      case "SongRequest":
        message.reText = rMessage.textMessage;
        break;
      case "Audio":
        message.reMediaLength = getAudioTime(rMessage.audioLength);
        break;
      case "Picture":
      case "Gif":
        message.reFileUrl = rMessage.filePath;
        break;
      case "TextPicture":
      case "TextGif":
        message.reText = rMessage.textMessage;
        message.reFileUrl = rMessage.filePath;
        break;
      case "Video":
        message.reFileUrl = rMessage.videoThumb;
        message.reMediaLength = rMessage.videoLength;
        break;
      case "TextVideo":
        message.reText = rMessage.textMessage;
        message.reFileUrl = rMessage.videoThumb;
        message.reMediaLength = rMessage.videoLength;
        break;
    }
  }

  bookmark = {
    type: chatType,
    name: chatInfo.name ? chatInfo.name : null,
    id: chatKey,
    imageUrl: null,
    message: input,
    messageType: "Text",
    unread_messages: 0,
    lowCaseName: null,
    email: null,
    date: time,
    radioStream: null, //for radios || podcast
    creatorId: null, //for groups
  };

  switch (chatType) {
    case "RadioChat":
      if (chatInfo.radio_Stream) {
        bookmark.radioStream = chatInfo.radio_Stream;
        bookmark.name = chatInfo.radio_Name;
        bookmark.lowCaseName = chatInfo.radio_Name.toLowerCase();
        if (chatInfo.radio_image) {
          bookmark.imageUrl = chatInfo.radio_image;
        }
      } else {
        bookmark.radioStream = chatInfo.radioStream;
        if (chatInfo.imageUrl) {
          bookmark.imageUrl = chatInfo.imageUrl;
        }
      }

      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //message failed
          showToast("Error sending message. Try again later.");
        } else {
          //message sent
          bmRef.set(bookmark);
          //showToast("Petition sent successfully");
        }
      });
      break;
    case "privateChat":
      // chatRef = firebase.database()
      //     .ref("PrivateChat/" + userInfo.userId +"/" + chatKey + "/" + messageKey);
      bookmark.lowCaseName = chatInfo.name.toLowerCase();
      var friendChatRef = firebase
        .database()
        .ref(
          "PrivateChat/" + chatKey + "/" + userInfo.userId + "/" + messageKey
        );
      var friendBookmark = {
        type: "privateChat",
        name: userInfo.name,
        id: userInfo.userId,
        imageUrl: userInfo.profileImage,
        message: input,
        messageType: "Text",
        unread_messages: 0,
        lowCaseName: userInfo.name.toLowerCase(),
        email: userInfo.email,
        date: time,
        radioStream: null, //for radios
        creatorId: null, //for groups
      };
      if (chatInfo.profile_Image) {
        bookmark.imageUrl = chatInfo.profile_Image;
      } else if (chatInfo.imageUrl) {
        bookmark.imageUrl = chatInfo.imageUrl;
      }

      if (chatInfo.email) {
        bookmark.email = chatInfo.email;
      }

      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //request failed
          showToast("Error sending message. Try again later.");
        } else {
          //request sent
          bmRef.set(bookmark);
          var friendBmRef = firebase
            .database()
            .ref("Users/" + chatKey + "/Chat_Bookmarks/" + userInfo.userId);
          friendChatRef.set(message, function (error) {
            if (error) {
              //message failed
              showToast("Error sending friend message. Try again later.");
            } else {
              //message sent
              chatRef.child(messageKey).child("sent").set(true);
              friendBmRef.set(friendBookmark);
            }
          });
          //showToast("message sent successfully");
        }
      });

      break;
    case "GroupChat":
      bookmark.lowCaseName = chatInfo.name.toLowerCase();
      bookmark.email = chatInfo.email;
      if (chatInfo.imageUrl) {
        bookmark.imageUrl = chatInfo.imageUrl;
      }
      bookmark.creatorId = chatInfo.creatorId;

      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //message failed
          showToast("Error sending message. Try again later.");
        } else {
          //message sent
          bmRef.set(bookmark);
          var groupChatRef = firebase
            .database()
            .ref("GroupChats/" + chatKey + "/chat");
          var groupBmRef = firebase.database().ref("Users");

          for (let participant of groupList) {
            groupChatRef
              .child(participant)
              .child(messageKey)
              .set(message, function (error) {
                if (error) {
                  //request failed
                  showToast("Error sending message to group. Try again later.");
                } else {
                  //request sent
                  groupBmRef
                    .child(participant)
                    .child("Chat_Bookmarks")
                    .child(chatKey)
                    .set(bookmark);
                }
              });
          }
          //groupChatRef.child()
          //showToast("Petition sent successfully");
        }
      });
      break;
    case "podChat":
      bookmark.name = chatInfo.title ? chatInfo.title : chatInfo.name;
      bookmark.lowCaseName = bookmark.name.toLowerCase();
      bookmark.radioStream = chatInfo.feed
        ? chatInfo.feed
        : chatInfo.radioStream;
      bookmark.imageUrl = chatInfo.icon ? chatInfo.icon : chatInfo.imageUrl;
      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //message failed
          showToast("Error sending message. Try again later.");
        } else {
          //message sent
          bmRef.set(bookmark);
          //showToast("Petition sent successfully");
        }
      });
      break;
  }
}

export function sendGifMessage(
  input,
  bmRef,
  chatRef,
  rMessage,
  rKey,
  chatType,
  userInfo,
  chatInfo,
  chatKey,
  file,
  groupList
) {
  var time = new Date().getTime();
  var messageKey = chatRef.push().key;
  var bookmark;
  var message = {
    textMessage: null,
    userName: userInfo.name,
    userId: userInfo.userId,
    type: "Gif",
    isReply: false,
    rePosition: null,
    reSenderName: null,
    reSenderId: null,
    reType: null,
    reMessageKey: null,
    reText: null,
    reFileUrl: null,
    reMediaLength: null,
    messageTime: time,
    filePath: file,
    isUploaded: true,
    receiverName: chatInfo.name,
    receiverId: chatKey,
  };

  if (rKey) {
    message.isReply = true;
    //message.rePosition = position;
    message.reSenderId = rMessage.userId;
    message.reSenderName = rMessage.userName;
    message.reType = rMessage.type;
    message.reMessageKey = rKey;
    switch (rMessage.type) {
      case "Text":
      case "SongRequest":
        message.reText = rMessage.textMessage;
        break;
      case "Audio":
        message.reMediaLength = getAudioTime(rMessage.audioLength);
        break;
      case "Picture":
      case "Gif":
        message.reFileUrl = rMessage.filePath;
        break;
      case "TextPicture":
      case "TextGif":
        message.reText = rMessage.textMessage;
        message.reFileUrl = rMessage.filePath;
        break;
      case "Video":
        message.reFileUrl = rMessage.videoThumb;
        message.reMediaLength = rMessage.videoLength;
        break;
      case "TextVideo":
        message.reText = rMessage.textMessage;
        message.reFileUrl = rMessage.videoThumb;
        message.reMediaLength = rMessage.videoLength;
        break;
    }
  }

  bookmark = {
    type: chatType,
    name: chatInfo.name,
    id: chatKey,
    imageUrl: null,
    message: null,
    messageType: "Gif",
    unread_messages: 0,
    lowCaseName: null,
    email: null,
    date: time,
    radioStream: null, //for radios
    creatorId: null, //for groups
  };
  if (input) {
    message.textMessage = input;
    message.type = "TextGif";
    bookmark.message = input;
    bookmark.messageType = "TextGif";
  }
  switch (chatType) {
    case "RadioChat":
      if (chatInfo.radio_Stream) {
        message.receiverName = chatInfo.radio_Name;
        bookmark.radioStream = chatInfo.radio_Stream;
        bookmark.name = chatInfo.radio_Name;
        bookmark.lowCaseName = chatInfo.radio_Name.toLowerCase();
        if (chatInfo.radio_image) {
          bookmark.imageUrl = chatInfo.radio_image;
        }
      } else {
        bookmark.radioStream = chatInfo.radioStream;
        if (chatInfo.imageUrl) {
          bookmark.imageUrl = chatInfo.imageUrl;
        }
      }

      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //message failed
          showToast("Error sending message. Try again later.");
        } else {
          //message sent
          bmRef.set(bookmark);
          //showToast("Petition sent successfully");
        }
      });
      break;
    case "privateChat":
      // chatRef = firebase.database()
      //     .ref("PrivateChat/" + userInfo.userId +"/" + chatKey + "/" + messageKey);
      bookmark.lowCaseName = chatInfo.name.toLowerCase();
      var friendChatRef = firebase
        .database()
        .ref(
          "PrivateChat/" + chatKey + "/" + userInfo.userId + "/" + messageKey
        );
      var friendBookmark = {
        type: "privateChat",
        name: userInfo.name,
        id: userInfo.userId,
        imageUrl: userInfo.profileImage,
        message: input,
        messageType: "Text",
        unread_messages: 0,
        lowCaseName: userInfo.name.toLowerCase(),
        email: userInfo.email,
        date: time,
        radioStream: null, //for radios
        creatorId: null, //for groups
      };
      if (chatInfo.profile_Image) {
        bookmark.imageUrl = chatInfo.profile_Image;
      } else if (chatInfo.imageUrl) {
        bookmark.imageUrl = chatInfo.imageUrl;
      }

      if (chatInfo.email) {
        bookmark.email = chatInfo.email;
      }

      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //request failed
          showToast("Error sending message. Try again later.");
        } else {
          //request sent
          bmRef.set(bookmark);
          var friendBmRef = firebase
            .database()
            .ref("Users/" + chatKey + "/Chat_Bookmarks/" + userInfo.userId);
          friendChatRef.set(message, function (error) {
            if (error) {
              //message failed
              showToast("Error sending friend message. Try again later.");
            } else {
              //message sent
              chatRef.child(messageKey).child("sent").set(true);
              friendBmRef.set(friendBookmark);
            }
          });
          //showToast("message sent successfully");
        }
      });

      break;
    case "GroupChat":
      bookmark.lowCaseName = chatInfo.name.toLowerCase();
      bookmark.email = chatInfo.email;
      if (chatInfo.imageUrl) {
        bookmark.imageUrl = chatInfo.imageUrl;
      }
      bookmark.creatorId = chatInfo.creatorId;

      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //message failed
          showToast("Error sending petition. Try again later.");
        } else {
          //message sent
          bmRef.set(bookmark);
          var groupChatRef = firebase
            .database()
            .ref("GroupChats/" + chatKey + "/chat");
          var groupBmRef = firebase.database().ref("Users");

          for (let participant of groupList) {
            groupChatRef
              .child(participant)
              .child(messageKey)
              .set(message, function (error) {
                if (error) {
                  //request failed
                  showToast("Error sending message to group. Try again later.");
                } else {
                  //request sent
                  groupBmRef
                    .child(participant)
                    .child("Chat_Bookmarks")
                    .child(chatKey)
                    .set(bookmark);
                }
              });
          }
          //groupChatRef.child()
          //showToast("Petition sent successfully");
        }
      });
      break;
    case "podChat":
      bookmark.name = chatInfo.title ? chatInfo.title : chatInfo.name;
      bookmark.lowCaseName = bookmark.name.toLowerCase();
      bookmark.radioStream = chatInfo.feed;
      bookmark.imageUrl = chatInfo.icon;
      chatRef.child(messageKey).set(message, function (error) {
        if (error) {
          //message failed
          showToast("Error sending message. Try again later.");
        } else {
          //message sent
          bmRef.set(bookmark);
          //showToast("Petition sent successfully");
        }
      });
      break;
  }
}

export function disableButton(btn, disable) {
  if (!btn.style) {
    btn = document.getElementById(btn);
  }

  btn.disabled = disable;
}

export function setBackgroundImage(element, image, url) {
  if (!element.style) {
    element = document.getElementById(element);
  }
  if (url) {
    element.style.backgroundImage =
      "url(" + addSizeToGoogleProfilePic(url) + ")";
  } else {
    element.style.backgroundImage = "url('" + image + "')";
  }
}

export function addSizeToGoogleProfilePic(url) {
  if (url.indexOf("googleusercontent.com") !== -1 && url.indexOf("?") === -1) {
    return url + "?sz=150";
  }
  return url;
}

export function linkify(inputText) {
  if (inputText.includes('name="')) {
    return inputText;
  }
  var replacedText, replacePattern1, replacePattern2, replacePattern3;
  //URLs starting with http://, https://, or ftp://
  replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export var appId = "d6b72287542044a9bd74d97db02fdd54";

export function dragElement(elmnt) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(elmnt.id + "Header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "Header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;

    //set element's right position
    //   if(elmnt.style.right != null && elmnt.style.right != "auto"){
    //     var offsetright = window.innerWidth-elmnt.offsetLeft-elmnt.offsetWidth;
    //     elmnt.style.right = (offsetright +pos1) + "px";
    //   }
    // set the element's new position:
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
    //elmnt.style.right = "auto";
  }
}
export function saveLocalFile(fileName, urlFile) {
  let a = document.createElement("a");
  a.style = "display: none";
  document.body.appendChild(a);
  a.href = urlFile;
  a.download = fileName;
  a.click();
  //window.URL.revokeObjectURL(url);
  a.remove();
}

export function saveFile(fileName, urlFile) {
  (async () => {
    //create new a element
    let a = document.createElement("a");
    // get image as blob
    let response = await fetch(urlFile);
    let file = await response.blob();
    // use download attribute https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#Attributes
    a.download = fileName;
    a.href = window.URL.createObjectURL(file);

    //store download url in javascript https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes#JavaScript_access
    a.dataset.downloadurl = [
      "application/octet-stream",
      a.download,
      a.href,
    ].join(":");
    //click on element to start download
    a.click();
    a.remove();
  })();
}
export function copyText(el, id) {
  var r = document.createRange();
  r.selectNode(el ? el : document.getElementById(id));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(r);
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
}
export function copyInput(el) {
  if (el != null) {
    // Select the text field
    el.select();
    el.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(el.value);

    // Alert the copied text
  }
}
export const gphClose = phClose;
export const gphGif = phGif;

export function getGenre(lang, index) {
  var genre;
  if (lang == null) {
    lang = "en";
  }
  if (lang === "en") {
    genre = genres[index];
  } else if (lang === "es") {
    genre = generos[index];
  }
  return genre;
}
export function getGenreIndex(lang, genre) {
  if (lang == null) {
    lang = "en";
  }

  if (lang === "en") {
    return genres.indexOf(genre);
  } else if (lang === "es") {
    return generos.indexOf(genre);
  }
}

export function getGenreList(lang) {
  if (lang == null) {
    lang = "en";
  }
  if (lang === "en") {
    return genres;
  } else if (lang === "es") {
    return generos;
  }
}

export function getLangList(lang) {
  if (lang == null) {
    lang = "en";
  }
  if (lang === "en") {
    return langs;
  } else if (lang === "es") {
    return lengs;
  }
}

export function getLangCode(index) {
  return langCode[index];
}

export function getLangIndex(code) {
  return langCode.indexOf(code);
}

export function getLangName(lang, index) {
  var name;
  if (lang == null) {
    lang = "en";
  }
  if (lang === "en") {
    name = langs[index];
  } else if (lang === "es") {
    name = lengs[index];
  }
  return name;
}

export function getCountryList(lang) {
  if (lang == null) {
    lang = "en";
  }
  if (lang === "en") {
    return countryList;
  } else if (lang === "es") {
    return paisList;
  }
}
export function getCountryCode(index) {
  return countryCodeList[index];
}

export function getCountryIndex(code) {
  return countryCodeList.indexOf(code);
}

export function getCountryName(lang, index) {
  var name;
  if (lang == null) {
    lang = "en";
  }
  if (lang === "en") {
    name = countryList[index];
  } else if (lang === "es") {
    name = paisList[index];
  }
  return name;
}

export function reformatPaste() {
  $("[contenteditable]").on("paste", function (e) {
    e.preventDefault();
    var text = "";
    if (e.clipboardData || e.originalEvent.clipboardData) {
      text = (e.originalEvent || e).clipboardData.getData("text/plain");
    } else if (window.clipboardData) {
      text = window.clipboardData.getData("Text");
    }
    if (document.queryCommandSupported("insertText")) {
      document.execCommand("insertText", false, text);
    } else {
      document.execCommand("paste", false, text);
    }
  });
}

export function getRandomNum(n) {
  var add = 1,
    max = 12 - add;

  if (n > max) {
    return generate(max) + generate(n - max);
  }
  max = Math.pow(10, n + add);
  var min = max / 10; // Math.pow(10, n) basically
  var number = Math.floor(Math.random() * (max - min + 1)) + min;

  return ("" + number).substring(add);
}

export function getPodClass() {
  return podClass;
}

export function validateEmail(input) {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return reg.test(input);
}
export function validateURL(input) {
  let reg =
    /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
  ///^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
  return reg.test(input);
}
export function validateLettersNums() {
  let reg = /^([a-zA-Z0-9 ]+)$/;

  return reg.test(input);
}
export function removeTags(str) {
  if (str === null || str === "") return;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function parseDuration(duration) {
  let remain = duration;

  let days = Math.floor(remain / (1000 * 60 * 60 * 24));
  remain = remain % (1000 * 60 * 60 * 24);

  let hours = Math.floor(remain / (1000 * 60 * 60));
  remain = remain % (1000 * 60 * 60);

  let minutes = Math.floor(remain / (1000 * 60));
  remain = remain % (1000 * 60);

  let seconds = Math.floor(remain / 1000);
  remain = remain % 1000;

  let milliseconds = remain;

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}

export function formatTimeHMS(o) {
  let hours = o.hours.toString();
  if (hours == "0") {
    hours = "";
  } else if (hours.length === 1) {
    hours = "0" + hours + ":";
  } else {
    hours = hours + ":";
  }

  let minutes = o.minutes.toString();
  if (minutes.length === 1) minutes = "0" + minutes;

  let seconds = o.seconds.toString();
  if (seconds.length === 1) seconds = "0" + seconds;

  return hours + minutes + ":" + seconds;
}

export function formatDurationHMS(duration) {
  let time = parseDuration(duration);
  return formatTimeHMS(time);
}

export const DATABASE_IN_APP_PRODUCTS =
  "https://chat-murcianys-18752.firebaseio.com/";

export function scrollRight(parent, btnR, btnL, display, amount) {
  if (parent == null || btnR == null || btnL == null) {
    return;
  }
  parent.scrollLeft += amount ? amount : 100;

  var isEnd = parent.scrollLeft >= parent.scrollWidth - parent.offsetWidth;
  //var isStart = parent.scrollLeft == 0 ? true : false;
  if (isEnd) {
    setDisplay(btnR, "none");
  }
  setDisplay(btnL, display ? display : "block");
}
export function scrollLeft(parent, btnR, btnL, display, amount) {
  if (parent == null || btnR == null || btnL == null) {
    return;
  }
  parent.scrollLeft -= amount ? amount : 100;

  //var isEnd = parent.scrollLeft >= parent.scrollWidth - parent.offsetWidth;
  var isStart = parent.scrollLeft == 0 ? true : false;
  if (isStart) {
    setDisplay(btnL, "none");
  }
  setDisplay(btnR, display ? display : "block");
}
export function scrollTop(parent, btnT, btnB, display, amount) {
  if (parent == null || btnT == null || btnB == null) {
    return;
  }

  parent.scrollTop -= amount ? amount : 100;

  //var isBottom = parent.scrollTop >= parent.scrollHeight - parent.offsetHeight;

  var isTop = parent.scrollTop == 0 ? true : false;
  if (isTop) {
    setDisplay(btnT, "none");
  }
  setDisplay(btnB, display ? display : "block");
}

export function scrollBtm(parent, btnT, btnB, display, amount) {
  if (parent == null || btnT == null || btnB == null) {
    return;
  }

  parent.scrollTop += amount ? amount : 100;

  var isBottom = parent.scrollTop >= parent.scrollHeight - parent.offsetHeight;

  //var isTop = parent.scrollTop == 0 ? true : false;
  if (isBottom) {
    setDisplay(btnB, "none");
  }
  setDisplay(btnT, display ? display : "block");
}
export const GroupType = { group: "GROUP", radio: "RADIO", podcast: "PODCAST" };

initCommonViews();
